.dashboard {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.dashboard-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.dashboard-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.dashboard-tabs {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  background: #f3f4f6;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.dashboard-tab {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dashboard-tab.active {
  background: white;
  color: #2563eb;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.dashboard-tab:hover:not(.active) {
  background: rgba(255,255,255,0.5);
}

/* Sidebar Styles */
.dashboard-sidebar {
  background-color: #ffffff;
  padding: 2rem;
  border-right: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 250px;
  height: 100vh;
}

.sidebar-header {
  margin-bottom: 3rem;
  text-align: center;
}

.sidebar-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.sidebar-header p {
  color: #6c757d;
  font-size: 0.875rem;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.nav-item {
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: none;
  background: none;
  color: #495057;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-item:hover {
  background-color: #f8f9fa;
  color: #000;
}

.nav-item.active {
  background-color: #e9ecef;
  color: #000;
  font-weight: 500;
}

.sidebar-footer {
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}

.sidebar-footer p {
  color: #6c757d;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.logout-btn {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dc3545;
  border-radius: 8px;
  background: none;
  color: #dc3545;
  cursor: pointer;
  transition: all 0.2s ease;
}

.logout-btn:hover {
  background-color: #dc3545;
  color: white;
}

/* Main Content Styles */
.dashboard-main {
  padding: 2rem;
  margin-left: 250px;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.search-filters {
  display: flex;
  gap: 1rem;
  flex: 1;
  max-width: 800px;
}

.search-input {
  flex: 2;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.875rem;
}

.filter-select {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.875rem;
  background-color: white;
}

.add-product-btn {
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.add-product-btn:hover {
  background-color: #218838;
}

/* Products Grid */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.product-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.product-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.featured-badge {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #ffd700;
  color: #000;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
}

.discount-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #dc3545;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
}

.product-info {
  padding: 1.5rem;
}

.product-info h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  font-weight: 500;
}

.product-category {
  color: #6c757d;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.product-description {
  color: #495057;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.product-price {
  font-size: 1.25rem;
  font-weight: 600;
  color: #28a745;
}

.product-stock {
  color: #6c757d;
  font-size: 0.875rem;
}

.product-actions {
  display: flex;
  gap: 0.5rem;
}

.edit-btn,
.delete-btn {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.edit-btn {
  background-color: #007bff;
  color: white;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.delete-btn:hover {
  background-color: #c82333;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.close-modal {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.add-product-form {
  padding: 1.5rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
  font-size: 0.875rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 0.875rem;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: auto;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e9ecef;
}

.cancel-btn,
.submit-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-btn {
  background: none;
  border: 1px solid #6c757d;
  color: #6c757d;
}

.cancel-btn:hover {
  background-color: #6c757d;
  color: white;
}

.submit-btn {
  background-color: #28a745;
  color: white;
  border: none;
}

.submit-btn:hover {
  background-color: #218838;
}

.submit-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Loading and No Products States */
.loading,
.no-products {
  text-align: center;
  padding: 3rem;
  color: #6c757d;
  font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 1rem;
  }

  .dashboard-container {
    grid-template-columns: 1fr;
  }

  .dashboard-sidebar {
    display: none;
  }

  .dashboard-main {
    margin-left: 0;
  }

  .search-filters {
    flex-direction: column;
  }

  .main-header {
    flex-direction: column;
    gap: 1rem;
  }

  .add-product-btn {
    width: 100%;
  }

  .dashboard-tabs {
    flex-wrap: wrap;
  }

  .dashboard-tab {
    flex: 1 1 auto;
    text-align: center;
  }
}

/* Orders Styles */
.orders-container {
  padding: 1rem;
}

.orders-container h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.orders-list {
  display: grid;
  gap: 1.5rem;
}

.order-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.order-header h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.order-status {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-pending {
  background-color: #ffd700;
  color: #000;
}

.status-processing {
  background-color: #007bff;
  color: white;
}

.status-shipped {
  background-color: #28a745;
  color: white;
}

.status-delivered {
  background-color: #6c757d;
  color: white;
}

.status-cancelled {
  background-color: #dc3545;
  color: white;
}

.order-details {
  margin-bottom: 1rem;
}

.order-details p {
  margin: 0.5rem 0;
  color: #495057;
  font-size: 0.875rem;
}

.order-items {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}

.order-items h4 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333;
}

.order-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  color: #495057;
  font-size: 0.875rem;
}

.order-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}

.btn-update,
.btn-details {
  flex: 1;
  padding: 0.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
}

.btn-update {
  background-color: #28a745;
  color: white;
}

.btn-update:hover {
  background-color: #218838;
}

.btn-details {
  background-color: #6c757d;
  color: white;
}

.btn-details:hover {
  background-color: #5a6268;
}

/* Statistics Styles */
.stats-container {
  padding: 1rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  text-align: center;
}

.stat-card h3 {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 2rem;
  font-weight: 600;
  color: #28a745;
}

.top-products {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.top-products h3 {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  color: #333;
}

.top-products-list {
  display: grid;
  gap: 1rem;
}

.top-product-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  position: relative;
}

.rank {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  width: 2rem;
  height: 2rem;
  background: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
}

.top-product-card img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
}

.top-product-info {
  flex: 1;
}

.top-product-info h4 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  color: #333;
}

.top-product-info p {
  margin: 0;
  font-size: 0.875rem;
  color: #6c757d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }

  .top-product-card {
    flex-direction: column;
    text-align: center;
  }

  .top-product-card img {
    margin: 1rem 0;
  }

  .order-header {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }

  .order-actions {
    flex-direction: column;
  }
}

.product-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-form h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #666;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.form-section {
  margin: 2rem 0;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.section-title {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
  display: block;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #666;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox-single {
  margin: 1rem 0;
}

.checkbox-single label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;
}

.submit-btn,
.cancel-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-btn {
  background: #007bff;
  color: white;
}

.submit-btn:hover {
  background: #0056b3;
}

.submit-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.cancel-btn {
  background: #f8f9fa;
  color: #666;
  border: 1px solid #e0e0e0;
}

.cancel-btn:hover {
  background: #e9ecef;
}

/* Estilos para el input de archivo */
input[type="file"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px dashed #e0e0e0;
  border-radius: 4px;
  background: #f8f9fa;
  cursor: pointer;
}

input[type="file"]:hover {
  border-color: #007bff;
}

/* Responsive */
@media (max-width: 768px) {
  .product-form {
    padding: 1rem;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .checkbox-group {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .form-actions {
    flex-direction: column-reverse;
  }

  .submit-btn,
  .cancel-btn {
    width: 100%;
  }
}

.image-preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.image-preview {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
}

.image-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  color: #dc3545;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-image:hover {
  background: #dc3545;
  color: white;
}

.file-input {
  padding: 1rem;
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-input:hover {
  border-color: #007bff;
  background: #f8f9fa;
}

.no-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  color: #6c757d;
  font-size: 0.9rem;
}

.product-image:empty {
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #666;
}

.product-image:empty::after {
  content: 'Sin imagen';
}

/* Estilos para la sección de pedidos */
.status-select {
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: white;
  font-size: 0.875rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

.status-select:focus {
  outline: none;
  border-color: #007bff;
}

.order-item-detail {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1rem;
}

.item-info {
  flex: 1;
}

.item-name {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.item-details {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.item-price {
  font-weight: 500;
  color: #28a745;
}

.order-info {
  margin-bottom: 2rem;
}

.order-info h3,
.order-items h3,
.order-status-update h3,
.order-summary h3 {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}

.order-summary {
  margin-top: 2rem;
}

.order-summary .total {
  font-size: 1.1rem;
  color: #28a745;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
}

.order-status-update {
  margin: 2rem 0;
}

/* Estados de los pedidos */
.status-pending {
  background-color: #ffc107;
}

.status-processing {
  background-color: #17a2b8;
}

.status-shipped {
  background-color: #007bff;
}

.status-delivered {
  background-color: #28a745;
}

.status-cancelled {
  background-color: #dc3545;
}

.orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.add-order-btn {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.add-order-btn:hover {
  background-color: #0056b3;
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.stats-container {
  animation: fadeIn 0.3s ease-out;
}

.stat-card {
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: both;
}

.stat-card:nth-child(1) { animation-delay: 0.1s; }
.stat-card:nth-child(2) { animation-delay: 0.2s; }
.stat-card:nth-child(3) { animation-delay: 0.3s; }
.stat-card:nth-child(4) { animation-delay: 0.4s; }

.content-section {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.content-group {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
  border: 1px solid #eee;
  transition: all 0.3s ease;
}

.content-group:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  border-color: #ddd;
}

.content-group h3 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #333;
}

.category-item {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid #eee;
  transition: all 0.3s ease;
}

.category-item:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  border-color: #ddd;
}

.category-item h4 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #333;
}

.image-upload {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.image-upload img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.image-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.image-upload input[type="file"]:hover + .upload-placeholder {
  border-color: #007bff;
  color: #007bff;
}

.upload-placeholder {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
  color: #666;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.image-preview {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.image-preview img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.remove-image {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  color: #666;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.remove-image:hover {
  background: white;
  color: #dc3545;
  transform: scale(1.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
}

.save-button {
  position: sticky;
  bottom: 2rem;
  width: 100%;
  padding: 1rem;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.save-button:hover {
  background: #218838;
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(0,0,0,0.15);
}

.save-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.preview-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 2rem;
}

.preview-content {
  background: white;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid #dee2e6;
}

.preview-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #333;
}

.preview-subtitle {
  font-size: 0.9rem;
  color: #666;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section-badge {
  padding: 0.5rem 1rem;
  background: #f8f9fa;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #666;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

@media (max-width: 768px) {
  .grid-layout {
    grid-template-columns: 1fr;
  }
  
  .content-section {
    padding: 1rem;
  }
  
  .content-group {
    padding: 1.5rem;
  }
} 